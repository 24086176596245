import React from 'react'
import Othersolution from '../Component/Othersolution';
import Ourgoal from '../Component/Ourgoal';
import googleplay from '../Image/googleplaybest.svg'
import appstore from '../Image/appstore.svg'
import mobile from '../Image/mobile.svg'
import { NavLink } from 'react-router-dom'

const Home = () => {
  return (
    <>
    <div className="nav-backgnd">
            <div className="container">
                <div className="row best-main">
                    <div className="col-md-8 col-sm-7">
                        <div className='best-text'>
                            <h1>Best Mobile App Template For Your Business</h1>
                            <p>App ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div className='port-img'>
                            <div>                           
                                <a  href="https://play.google.com/store/apps/developer?id=Mechodal+Technology"><img src={googleplay} alt="" /></a>
                            </div>
                            <div>                           
                                <a  href="#"><img src={appstore} alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-5 d-flex justify-content-center">
                        <div className='mobile-img'>
                            <img src={mobile} className='img-fluid' alt="" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    <Othersolution/>
    <Ourgoal/>
    </>
  )
}

export default Home