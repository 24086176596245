import React, { useState } from 'react'
import Soluapi from '../Basic/Soluapi'
import Fastchat from '../Image/bestreadlogo.webp'
import Mumemos from '../Image/taxnooklogo.svg'
import Musicplayer from '../Image/finacalfrinedlogo.svg'
import Powersaver from '../Image/platedlogo.svg'
import Stockshub from '../Image/meechologo.webp'
import Equalizer from '../Image/tempokeyslogo.webp'
import Smartclock from '../Image/smartclock.svg'
import vaxplayer from '../Image/vaxplayer.svg'
import { NavLink } from 'react-router-dom'

const Othersolution = () => {

    const [soludata,setSoludata]=useState(Soluapi);

  return (
    <>
    
    <div className="other-main pb-4">
        <div className="container">
            <div className="other-text">
                <h2>Other Solutions by Mechodal</h2>
                <p>We create amazing mobile & desktop Apps. Most of our apps are
                available on Windows, Linux, Mac, iPhone, iPad, and Android</p>
            </div>
            <div className="row">
            {
    soludata.map((curr)=>{
        return(
        <div className="col-lg-3 col-md-4 col-sm-6 ">
            <div className="fastchat-sec">
                <div>
                    <img src={curr.image} alt="" />
                </div>
                <div className='fast-text'>
                    <h4>{curr.heading}</h4>
                    <h6><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i>{curr.rating}</h6>
                    <p>{curr.discription}</p>
                    <NavLink to={`/${curr.link}`}><a href="">View</a></NavLink>
                </div>
            </div>
        </div>
    )
    })
}

                {/* <div className="col-lg-3 col-md-4 col-sm-6 ">
                    <div className="fastchat-sec">
                        <div>
                            <img src={Fastchat} alt="" />
                        </div>
                        <div className='fast-text'>
                            <h4>BestReads</h4>
                            <h6><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i> 4.5</h6>
                            <p>Aliquam an augue luctus undo purus</p>
                            <NavLink to="/read"><a href="">View</a></NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 ">
                    <div className="fastchat-sec">
                        <div>
                            <img src={Mumemos} alt="" />
                        </div>
                        <div className='fast-text'>
                            <h4>Taxnook</h4>
                            <h6><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i> 4.2</h6>
                            <p>Aliquam an augue luctus undo purus</p>
                            <NavLink to="/taxnook"><a href="">View</a></NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 ">
                    <div className="fastchat-sec">
                        <div>
                            <img src={Musicplayer} alt="" />
                        </div>
                        <div className='fast-text'>
                            <h4>FincalFriend</h4>
                            <h6><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i> 4.1</h6>
                            <p>Aliquam an augue luctus undo purus</p>
                            <NavLink to="/fincalfriend"><a href="">View</a></NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 ">
                    <div className="fastchat-sec">
                        <div>
                            <img src={Powersaver} alt="" />
                        </div>
                        <div className='fast-text'>
                            <h4>PalettePad</h4>
                            <h6><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i> 4.8</h6>
                            <p>Aliquam an augue luctus undo purus</p>
                            <NavLink to="/plated"><a href="">View</a></NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 ">
                    <div className="fastchat-sec">
                        <div>
                            <img src={Stockshub} alt="" />
                        </div>
                        <div className='fast-text'>
                            <h4>Meecho</h4>
                            <h6><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i> 4.5</h6>
                            <p>Aliquam an augue luctus undo purus</p>
                            <NavLink to="/meecho"><a href="">View</a></NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 ">
                    <div className="fastchat-sec">
                        <div>
                            <img src={Equalizer} alt="" />
                        </div>
                        <div className='fast-text'>
                            <h4>TempoKeys</h4>
                            <h6><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i> 4.5</h6>
                            <p>Aliquam an augue luctus undo purus</p>
                            <NavLink to="/tempokeys"><a href="">View</a></NavLink>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    </>
  )
}

export default Othersolution






{/* <div className="col-lg-3 col-md-4 col-sm-6 ">
<div className="fastchat-sec">
    <div>
        <img src={Smartclock} alt="" />
    </div>
    <div className='fast-text'>
        <h4>SmartClock</h4>
        <h6><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i> 4.5</h6>
        <p>Aliquam an augue luctus undo purus</p>
        <NavLink to="/read"><a href="">View</a></NavLink>
    </div>
</div>
</div>
<div className="col-lg-3 col-md-4 col-sm-6 ">
<div className="fastchat-sec">
    <div>
        <img src={vaxplayer} alt="" />
    </div>
    <div className='fast-text'>
        <h4>VAX Player</h4>
        <h6><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i> 4.1</h6>
        <p>Aliquam an augue luctus undo purus</p>
        <NavLink to="/read"><a href="">View</a></NavLink>
    </div>
</div>
</div> */}