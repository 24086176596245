import React from 'react'
import Xsmart from '../Component/Xsmart';
import googleplay from '../Image/googleplaybest.svg'
import Readapp from '../Image/readapp.svg'

const Read = () => {
  return (
    <> 
    <div className="nav-backgnd shadow-none">
            <div className="container">
                <div className="row best-read-main">
                    <div className="col-md-8 col-sm-7">
                        <div className='best-read-text'>
                            <h1><span>Best</span>Reads App</h1>
                            <p>Discover and explore the best books with BestReads</p>
                        </div>
                        <div className='port-img'>
                            <div>                           
                                <a  href="https://play.google.com/store/apps/details?id=com.mechodal.bestreads"><img src={googleplay} alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-5 d-flex justify-content-center">
                        <div className='mobileapp-img'>
                            <img src={Readapp} className='img-fluid' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Xsmart/>
    </>
  )
}

export default Read