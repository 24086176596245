import React from 'react'
import googleplay from '../Image/googleplaybest.svg'
import Readapp from '../Image/readapp.svg'
import fincalfriend from '../Image/fincalfriend.webp'
import finaclscreen from '../Image/finaclscreen.svg'

const Fincalfriend = () => {
  return (
    <>
        <div className="nav-backgnd">
            <div className="container">
                <div className="row best-read-main">
                    <div className="col-md-8 col-sm-7">
                        <div className='best-read-text'>
                            <h1>FincalFriend App</h1>
                            <p>Effortlessly calculate EMIs with FincalFriend</p>
                        </div>
                        <div className='port-img'>
                            <div>                           
                                <a  href="#"><img src={googleplay} alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-5 d-flex justify-content-center">
                        <div className='mobileapp-img'>
                            <img src={finaclscreen} className='img-fluid' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div className="container">
      <div className="xsmart-main">
       <div className="xsmart-text">
          <h4>What is a FincalFriend?</h4>
          <p>"FincalFriend is a powerful financial calculator app that simplifies the process of calculating Equated Monthly Installments (EMIs) for loans and mortgages. Whether you're planning to buy a car, a home, or need to manage your existing loans, FincalFriend is your go-to companion for accurate and hassle-free EMI calculations.</p>
          <p>With FincalFriend, you can easily input the loan amount, interest rate, and tenure to instantly obtain the monthly installment amount. The app supports a wide range of loan types, including housing loans, car loans, personal loans, and more, making it versatile and useful for various financial scenarios.</p>
          <p>Additionally, FincalFriend provides you with a detailed breakdown of the repayment schedule, showing the interest and principal components of each EMI. This helps you gain a better understanding of how your payments are structured over time and allows you to plan your finances more effectively.</p>
       </div>

       <div className="getproduct-sec">
          <div className="row d-flex align-items-center">
              <div className="col-md-7">
                  <div className="getsol-text">
                      <h4>Get 10x Productive Solution </h4>
                      <p>Main Admin Panel + Management Admin Panel + Support Team Application Get It Now </p>
                  </div>
                  <div className='getsol-img'>                        
                      <a  href="https://play.google.com/store/apps/details?id=com.FincalFriend.mechodal"><img src={googleplay} alt="" /></a>
                  </div>
              </div>
              <div className="col-md-5">
                  <div className="bestread-img">
                      <a href=""><img src={fincalfriend} className='img-fluid' alt="" /></a>
                  </div>
              </div>
          </div>
       </div>

       <div className="xsmart-text">
          {/* <h4>What is a xSmart 2?</h4> */}
          <p>The app also offers a built-in affordability calculator that assists you in determining the loan amount you can comfortably afford based on your monthly income and expenses. This feature helps you make informed decisions when considering loan options and ensures financial stability.</p>
          <p>FincalFriend prioritizes user-friendly design and intuitive navigation, ensuring that users of all levels can easily navigate the app and perform calculations effortlessly. It provides a clean and organized interface that allows you to switch between different loan types and customize parameters without any confusion.</p>
          <p>Download FincalFriend now and take control of your financial planning. Simplify your EMI calculations, make informed borrowing decisions, and manage your loans with confidence using FincalFriend - the ultimate financial calculator app."</p>
       </div>
      </div>
    </div>
    </>
  )
}

export default Fincalfriend