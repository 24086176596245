import React from 'react'
import googleplay from '../Image/googleplaybest.svg'
import Readapp from '../Image/readapp.svg'
import paletted from '../Image/paletted.webp'
import palettedscreen from '../Image/platedscreen.png'

const Plated = () => {
  return (
    <>
        <div className="nav-backgnd">
            <div className="container">
                <div className="row best-read-main">
                    <div className="col-md-8 col-sm-7">
                        <div className='best-read-text'>
                            <h1>PalettePad App</h1>
                            <p>PalettePad - Draw your Dream</p>
                        </div>
                        <div className='port-img'>
                            <div>                           
                                <a  href="#"><img src={googleplay} alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-5 d-flex justify-content-center">
                        <div className='mobileapp-img'>
                            <img src={palettedscreen} className='img-fluid' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div className="container">
      <div className="xsmart-main">
       <div className="xsmart-text">
          <h4>What is a PalettePad ?</h4>
          <p>PalettePad, developed by Mechodal Technology, is a powerful and versatile sketch pad app that allows you to unleash your creativity and express yourself through art. Whether you're a professional artist, an aspiring illustrator, or simply someone who loves to doodle, PalettePad provides you with the tools and features to bring your ideas to life.</p>
          <p>With PalettePad, you have a virtual canvas at your fingertips. Let your imagination run wild as you sketch, draw, and paint with a wide range of brushes, pens, and colors. From bold strokes to intricate details, PalettePad offers precision and control to help you create stunning artworks.</p>
          <p>Explore a variety of artistic tools and effects to enhance your creations. Experiment with different brush types, adjust opacity and thickness, and blend colors seamlessly. Use layers to add depth and complexity to your artwork, and easily rearrange or modify them to achieve the desired result.</p>
       </div>

       <div className="getproduct-sec">
          <div className="row d-flex align-items-center">
              <div className="col-md-7">
                  <div className="getsol-text">
                      <h4>Get 10x Productive Solution </h4>
                      <p>Main Admin Panel + Management Admin Panel + Support Team Application Get It Now </p>
                  </div>
                  <div className='getsol-img'>                        
                      <a  href="https://play.google.com/store/apps/details?id=com.PalettePad.mechodal"><img src={googleplay} alt="" /></a>
                  </div>
              </div>
              <div className="col-md-5">
                  <div className="bestread-img">
                      <a href=""><img src={paletted} className='img-fluid' alt="" /></a>
                  </div>
              </div>
          </div>
       </div>

       <div className="xsmart-text">
          {/* <h4>What is a xSmart 2?</h4> */}
          <p>PalettePad is not just a sketching app—it's a complete art studio in your pocket. Take advantage of features like undo/redo, zoom in/out, and a customizable interface to tailor your workflow to your preferences. Whether you're a beginner or an experienced artist, PalettePad provides a user-friendly experience that encourages artistic exploration and growth.</p>
          <p>Share your creations with the world directly from the app. Showcase your artwork on social media platforms, send it to friends and family, or even print it out for physical display. PalettePad makes it easy to share and inspire others with your artistic talents.</p>
          <p>Unlock your creative potential with PalettePad. Whether you're sketching ideas, illustrating concepts, or simply doodling for relaxation, this app offers endless possibilities for artistic expression. Let your imagination flourish and create art that resonates with your unique style.</p>
          <p>For any inquiries or feedback, please reach out to us at app@mechodal.com. We value your input and strive to continuously enhance your artistic journey. Download PalettePad today and unleash the artist within you.</p>
       </div>
      </div>
    </div>
    </>
  )
}

export default Plated