import React from 'react'
import googleplay from '../Image/googleplaybest.svg'
import Readapp from '../Image/readapp.svg'
import meecho from '../Image/meecho.webp'
import meechoscreen from '../Image/meechoscreen.webp'

const Meecho = () => {
  return (
    <>
        <div className="nav-backgnd">
            <div className="container">
                <div className="row best-read-main">
                    <div className="col-md-8 col-sm-7">
                        <div className='best-read-text'>
                            <h1>Meecho App</h1>
                            <p>One Stop Loan Management Application</p>
                        </div>
                        <div className='port-img'>
                            <div>                           
                                <a  href="#"><img src={googleplay} alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-5 d-flex justify-content-center">
                        <div className='mobileapp-img'>
                            <img src={meechoscreen} className='img-fluid' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div className="container">
      <div className="xsmart-main">
       <div className="xsmart-text">
          <h4>What is a Meecho ?</h4>
            <p>Meecho is an application, in which any finance company can manage all its loans and loan users.</p>
          <p>This application is for the public local sellers or any finance company, Do you run a mobile shop, then a TV shop, or run a school, or an education company through the application, you can create and use a loan section from this for your customer</p>
          <p>Any local seller or any finance company admin can log in to admin panel, they can enter their loan through the admin panel and same can manage their ongoing loan and incoming loan.</p>
       </div>

       <div className="getproduct-sec">
          <div className="row d-flex align-items-center">
              <div className="col-md-7">
                  <div className="getsol-text">
                      <h4>Get 10x Productive Solution </h4>
                      <p>Main Admin Panel + Management Admin Panel + Support Team Application Get It Now </p>
                  </div>
                  <div className='getsol-img'>                        
                      <a  href="https://play.google.com/store/apps/details?id=com.mechodal.meecho"><img src={googleplay} alt="" /></a>
                  </div>
              </div>
              <div className="col-md-5">
                  <div className="bestread-img">
                      <a href=""><img src={meecho} className='img-fluid' alt="" /></a>
                  </div>
              </div>
          </div>
       </div>

       <div className="xsmart-text">
          {/* <h4>What is a xSmart 2?</h4> */}
          <p>Admin can create any user from his admin panel, the same user can use the app through login in this app.</p>
          <p>Users can apply for loans through its app, They can check where their loan request has reached, can pay due, He/She can Pay EMI Any Many More things.</p>
          <p>Note: Is there any online loan that is not available through the application, this is only a loan management app. Those local vendors who run their business offline will get help in running online.</p>
       </div>
      </div>
    </div>
    </>
  )
}

export default Meecho