import logo from './logo.svg';
import './App.css';
import Navbaar from './Component/Navbaar'
import Footer from './Component/Footer';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Pages/Home';
import Read from './Pages/Read';
import Taxnook from './Pages/Taxnook';
import Meecho from './Pages/Meecho';
import Fincalfriend from './Pages/Fincalfriend';
import Plated from './Pages/Plated';
import Tempokeys from './Pages/Tempokeys';

function App() {
  return (
    <>
    <Router>
    <Navbaar/>
      <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/read" element={<Read/>} />
          <Route path="/taxnook" element={<Taxnook/>} />
          <Route path="/fincalfriend" element={<Fincalfriend/>} />
          <Route path="/plated" element={<Plated/>} />
          <Route path="/meecho" element={<Meecho/>} />
          <Route path="/tempokeys" element={<Tempokeys/>} />
      </Routes>
    <Footer/>
    </Router> 
    {/* <Bestmobilepage/>
    <Bestreadpage/> */}
    </>
  );
}

export default App;
