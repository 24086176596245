import React from 'react'
import High from '../Image/high.svg'

const Ourgoal = () => {
  return (
    <>
   <div className="goal-main">
        <div className="container">
            <div className="goal-text">
                <h4>Our Goal</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6">
                    <div className="highreso-sec">
                        <div>
                            <img src={High} alt="" />
                        </div>
                        <div className='high-text'>
                            <h5>High Resolution</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="highreso-sec">
                        <div>
                            <img src={High} alt="" />
                        </div>
                        <div className='high-text'>
                            <h5>High Resolution</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="highreso-sec">
                        <div>
                            <img src={High} alt="" />
                        </div>
                        <div className='high-text'>
                            <h5>High Resolution</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="highreso-sec">
                        <div>
                            <img src={High} alt="" />
                        </div>
                        <div className='high-text'>
                            <h5>High Resolution</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="highreso-sec">
                        <div>
                            <img src={High} alt="" />
                        </div>
                        <div className='high-text'>
                            <h5>High Resolution</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="highreso-sec">
                        <div>
                            <img src={High} alt="" />
                        </div>
                        <div className='high-text'>
                            <h5>High Resolution</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
    </>
  )
}

export default Ourgoal