import React from 'react'
import logo from '../Image/mechlogo.svg'
import googleplay from '../Image/googleplay.svg'
import { NavLink } from 'react-router-dom'

const Navbaar = () => {
  return (
    <>
        <nav class="navbar navbar-light nav-backgnd sticky-top">
        <div class="container nav-sec">
            <div className="logo-sec">
            <NavLink to="/home"><a className="navbar-brand" href="#"><img  src={logo} alt="" /></a></NavLink>
            </div>
            <div className='google-sec'>
                <a className="navbar-brand" href="https://play.google.com/store/apps/developer?id=Mechodal+Technology"><img src={googleplay} alt="" /></a>
            </div>
        </div>
        </nav>
    </>
  )
}

export default Navbaar



