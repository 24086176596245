import React from 'react'
import googleplay from '../Image/googleplaybest.svg'
import Readapp from '../Image/readapp.svg'
import taxnook from '../Image/taxnook.webp'
import taxnookscreen from '../Image/taxnookscreen.svg'

const Taxnook = () => {
  return (
    <>
        <div className="nav-backgnd">
            <div className="container">
                <div className="row best-read-main">
                    <div className="col-md-8 col-sm-7">
                        <div className='best-read-text'>
                            <h1>Taxnook App</h1>
                            <p>Easily calculate personal taxes with TaxNook, your tax calculator app</p>
                        </div>
                        <div className='port-img'>
                            <div>                           
                                <a  href="#"><img src={googleplay} alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-5 d-flex justify-content-center">
                        <div className='mobileapp-img'>
                            <img src={taxnookscreen} className='img-fluid' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div className="container">
      <div className="xsmart-main">
       <div className="xsmart-text">
          <h4>What is a Taxnook?</h4>
          <p>TaxNook, developed by Mechodal Technology, is your comprehensive tax calculator app designed to simplify the process of calculating personal taxes. Whether you're an individual taxpayer, a freelancer, or a small business owner, TaxNook provides the tools and guidance you need to navigate the complexities of tax calculations.</p>
          <p>With TaxNook, calculating your personal taxes becomes effortless. Simply input your income, deductions, and credits, and the app will instantly generate accurate tax calculations based on the latest tax laws and regulations. Whether you're dealing with income tax, deductions for mortgage interest or medical expenses, or credits for dependents or education, TaxNook covers it all.</p>
          <p>Stay informed and up-to-date with TaxNook's real-time tax updates. The app keeps track of tax law changes and ensures that your calculations align with the latest tax codes, so you can have peace of mind knowing that your tax estimates are accurate and reliable.</p>
          <p>TaxNook provides a user-friendly interface that makes tax calculations accessible to everyone. Easily navigate through different tax categories, enter your financial information, and receive instant results. The app offers clear explanations and helpful tips to guide you through the tax calculation process, demystifying complex tax terminology and concepts.</p>
       </div>

       <div className="getproduct-sec">
          <div className="row d-flex align-items-center">
              <div className="col-md-7">
                  <div className="getsol-text">
                      <h4>Get 10x Productive Solution </h4>
                      <p>Main Admin Panel + Management Admin Panel + Support Team Application Get It Now </p>
                  </div>
                  <div className='getsol-img'>                        
                      <a  href="https://play.google.com/store/apps/details?id=com.TaxNook.mechodal"><img src={googleplay} alt="" /></a>
                  </div>
              </div>
              <div className="col-md-5">
                  <div className="bestread-img">
                      <a href=""><img src={taxnook} className='img-fluid' alt="" /></a>
                  </div>
              </div>
          </div>
       </div>

       <div className="xsmart-text">
          {/* <h4>What is a xSmart 2?</h4> */}
          <p>Take advantage of TaxNook's advanced features to optimize your tax planning. Estimate your tax liability for the upcoming year based on projected income and deductions, helping you make informed financial decisions. Explore different scenarios and evaluate the potential impact of tax-saving strategies, empowering you to maximize your tax savings.</p>
          <p>Your tax data is securely stored within the app, ensuring the confidentiality and privacy of your financial information. TaxNook follows industry-standard security measures to protect your data and provides peace of mind in handling sensitive tax-related details.</p>
          <p>For any inquiries or feedback, please reach out to us at app@mechodal.com. We value your input and strive to continuously improve your tax calculation experience. Download TaxNook today and gain confidence in your personal tax calculations, making tax season a breeze.</p>
       </div>
      </div>
    </div>
    </>
  )
}

export default Taxnook