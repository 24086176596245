import React from 'react'
import googleplay from '../Image/googleplay.svg'
import bestread from '../Image/bestreads.svg'

const Xsmart = () => {
  return (
    <>
    <div className="container">
      <div className="xsmart-main">
       <div className="xsmart-text">
          <h4>What is a BestReads?</h4>
          <p>BestReads, brought to you by Mechodal Technology, is your ultimate companion for discovering and exploring the best books across genres. Immerse yourself in a captivating world of literature, where you can embark on literary adventures and unlock the power of knowledge.</p>
          <p>With BestReads, you can dive into a vast library of books and discover hidden gems, timeless classics, and contemporary masterpieces. Whether you're a fan of gripping thrillers, heartwarming romance, thought-provoking science fiction, or informative non-fiction, BestReads has something for every reader.</p>
          <p>Explore curated collections meticulously handpicked by our team of literary enthusiasts, designed to introduce you to diverse voices and perspectives. Delve into popular genres, explore new releases, and rediscover old favorites—all at your fingertips. Our personalized recommendations take into account your reading preferences, ensuring you never run out of great books to devour.</p>
          <p>Find your next literary adventure with ease using our intuitive search and filtering options. From bestsellers to hidden treasures, from award-winning authors to emerging talents, BestReads is your compass in the world of literature. Dive into user reviews and ratings to get insights and opinions from fellow book lovers, helping you make informed choices.</p>
       </div>

       <div className="getproduct-sec">
          <div className="row d-flex align-items-center">
              <div className="col-md-7">
                  <div className="getsol-text">
                      <h4>Get 10x Productive Solution </h4>
                      <p>Main Admin Panel + Management Admin Panel + Support Team Application Get It Now </p>
                  </div>
                  <div className='getsol-img'>                        
                      <a  href="https://play.google.com/store/apps/details?id=com.mechodal.bestreads"><img src={googleplay} alt="" /></a>
                  </div>
              </div>
              <div className="col-md-5">
                  <div className="bestread-img">
                      <a href=""><img src={bestread} className='img-fluid' alt="" /></a>
                  </div>
              </div>
          </div>
       </div>

       <div className="xsmart-text">
          {/* <h4>What is a xSmart 2?</h4> */}
          <p>But BestReads is more than just a book discovery platform. It's a community of readers, a place to connect with like-minded individuals who share your passion for books. Engage in discussions, join book clubs, and expand your literary horizons through the power of shared reading experiences.</p>
          <p>Create your own reading lists to organize your virtual bookshelf and keep track of books you want to read, books you're currently reading, and books you've already enjoyed. Set reading goals, track your progress, and celebrate your literary achievements.</p>
          <p>BestReads is designed to be your trusted companion, available anytime and anywhere. Seamlessly sync your reading progress across devices, so you can pick up where you left off on your phone, tablet, or computer.</p>
          <p>Expand your knowledge, broaden your horizons, and embark on unforgettable literary journeys with BestReads. Immerse yourself in stories that transport you to new worlds, challenge your perspectives, and ignite your imagination.</p>
          <p>For any inquiries or feedback, please reach out to us at app@mechodal.com. We value your input and strive to continuously improve your reading experience. Join the BestReads community today and discover the joy of reading like never before.</p>
       </div>
      </div>
    </div>
    </>
  )
}

export default Xsmart