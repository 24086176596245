import React from 'react'
import googleplay from '../Image/googleplaybest.svg'
import Readapp from '../Image/readapp.svg'
import tempokeys from '../Image/tempokeys.webp'
import tempokeysscreen from '../Image/tempokeyscreen.svg'

const Tempokeys = () => {
  return (
    <>
        <div className="nav-backgnd">
            <div className="container">
                <div className="row best-read-main">
                    <div className="col-md-8 col-sm-7">
                        <div className='best-read-text'>
                            <h1>TempoKeys App</h1>
                            <p>TempoKeys - A Piano App</p>
                        </div>
                        <div className='port-img'>
                            <div>                           
                                <a  href="#"><img src={googleplay} alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-5 d-flex justify-content-center">
                        <div className='mobileapp-img'>
                            <img src={tempokeysscreen} className='img-fluid' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div className="container">
      <div className="xsmart-main">
       <div className="xsmart-text">
          <h4>What is a TempoKeys ?</h4>
          <p>TempoKeys, developed by Mechodal Technology, is a virtual piano app that allows you to play beautiful melodies and create music on the go. Whether you're a professional pianist, a music enthusiast, or someone looking to learn and explore the world of piano, TempoKeys provides an immersive and versatile experience.</p>
          <p>With TempoKeys, you have a full-sized, realistic piano right at your fingertips. The app offers a range of high-quality piano sounds, capturing the essence and nuances of a real acoustic instrument. From soft and gentle melodies to vibrant and powerful chords, TempoKeys allows you to express yourself musically with precision and authenticity.</p>
          <p>Explore different musical genres and styles with TempoKeys. Play classical compositions, jazz improvisations, pop hits, or even create your own original music. The app provides a wide range of octaves and customizable settings, allowing you to tailor your playing experience to your preferences.</p>
       </div>

       <div className="getproduct-sec">
          <div className="row d-flex align-items-center">
              <div className="col-md-7">
                  <div className="getsol-text">
                      <h4>Get 10x Productive Solution </h4>
                      <p>Main Admin Panel + Management Admin Panel + Support Team Application Get It Now </p>
                  </div>
                  <div className='getsol-img'>                        
                      <a  href="https://play.google.com/store/apps/details?id=com.mechodal.bestreads"><img src={googleplay} alt="" /></a>
                  </div>
              </div>
              <div className="col-md-5">
                  <div className="bestread-img">
                      <a href=""><img src={tempokeys} className='img-fluid' alt="" /></a>
                  </div>
              </div>
          </div>
       </div>

       <div className="xsmart-text">
          {/* <h4>What is a xSmart 2?</h4> */}
          <p>Whether you're a beginner or an advanced pianist, TempoKeys offers features to support your musical journey. Learn to play your favorite songs with the help of interactive tutorials, which provide visual cues and guidance for proper hand positioning and timing. Practice and improve your skills with various exercises and challenges designed to enhance your technique and musicality.</p>
          <p>TempoKeys is not just a virtual piano—it's a music creation tool. Record your performances, experiment with different rhythms and tempos, and layer multiple tracks to compose intricate musical arrangements. Add effects and modify the sound parameters to create unique and captivating musical experiences.</p>
          <p>Share your musical creations with others directly from the app. Connect with friends, family, and fellow musicians by sharing your performances on social media platforms, sending recordings via messaging apps, or even exporting audio files for professional use. TempoKeys makes it easy to showcase your musical talent and inspire others.</p>
          <p>For any inquiries or feedback, please reach out to us at app@mechodal.com. We value your input and strive to continuously enhance your musical experience. Download TempoKeys today and let your fingers dance across the virtual keys, creating beautiful melodies that resonate with your soul.</p>
       </div>
      </div>
    </div>
    </>
  )
}

export default Tempokeys