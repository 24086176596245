

import bestread from '../Image/bestreadlogo.svg'
import taxnook from '../Image/taxnooklogo.svg'
import plated from '../Image/finacalfrinedlogo.svg'
import finacalfrined from '../Image/platedlogo.svg'
import Equalizer from '../Image/equalizer.svg'
import tempokey from '../Image/tempokeylogo.svg'


const Soluapi = [
{
    id:1,
    heading:'BestReads',
    image:bestread,
    discription:'Discover and explore the best books with BestReads',
    link:'read',
    rating:'4.5'
},
{
    id:2,
    heading:'Taxnook',
    image:taxnook,
    discription:'Easily calculate personal taxes with TaxNook, your tax calculator app',
    link:'taxnook',
    rating:'4.2'
},
{
    id:3,
    heading:'FincalFriend',
    image:finacalfrined,
    discription:'Effortlessly calculate EMIs with FincalFriend',
    link:'fincalfriend',
    rating:'4.1'
},
{
    id:4,
    heading:'PalettePad',
    image:plated,
    discription:'PalettePad - Draw your Dream',
    link:'plated',
    rating:'4.8'
},
{
    id:5,
    heading:'Meecho',
    image:Equalizer,
    discription:'One Stop Loan Management Application',
    link:'meecho',
    rating:'4.5'
},
{
    id:6,
    heading:'TempoKeys',
    image:tempokey,
    discription:'TempoKeys - A Piano App',
    link:'tempokeys'
},  
]

export default Soluapi