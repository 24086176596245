import React from 'react'
import footerlogo from '../Image/footer-logo.svg'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <>
    <footer className='foot-back'>
      <div className="container">
        <div className="row foot-border">
          <div className="col-lg-4 col-md-12">
            <div className="foot-text">
              <NavLink to="/home"><a className="navbar-brand" href="#"><img  src={footerlogo} alt="" /></a></NavLink>
              <p>The journey is just as important as the destination. Let's make it memorable together.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="foot-heading">
              <h4>Latest App</h4>
              <div className="foot-link">
                <NavLink to="https://play.google.com/store/apps/details?id=com.TempoKeys.mechodal">TempoKeys- A Piano App</NavLink>
              </div>
              <div className="foot-link">
                <NavLink to="https://play.google.com/store/apps/details?id=com.PalettePad.mechodal">PalettePad - Draw your Dream</NavLink>
              </div>
              <div className="foot-link">
                <NavLink NavLink to="https://play.google.com/store/apps/details?id=com.FincalFriend.mechodal">FincalFriend</NavLink>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="foot-heading">
              <h4>Most Demanded </h4>
              <div className="foot-link">
                <NavLink to="https://play.google.com/store/apps/details?id=com.mechodal.meecho">Meecho - Loan Management App</NavLink>
              </div>
              <div className="foot-link">
                <NavLink to="https://play.google.com/store/apps/details?id=com.mechodal.bestreads">BestReads</NavLink>
              </div>
              <div className="foot-link">
                <NavLink NavLink to="https://play.google.com/store/apps/details?id=com.TaxNook.mechodal">Taxnook</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='zebu-text'>
          <p> Copyright @ 2023 <a href="https://mechodal.com/">Mechodal Technology.</a> All rights reserved </p>
      </div>

    </footer>
    </>
  )
}

export default Footer